import loadable from '@loadable/component';

export const Feed = loadable(
  () =>
    import(
      /* webpackChunkName: "FeedWidgetGroupFeed", webpackPrefetch: true */ './Feed'
    ),
  {
    resolveComponent: (component) => component.Feed,
  },
);
