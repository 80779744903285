import React from 'react';
import { useSelector } from 'react-redux';
import { ButtonPriority } from 'wix-ui-tpa';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

import { selectGroup } from 'store/groups/selectors';
import { selectAppData } from 'store/application/selectors';

import { UISref } from 'common/router';
import { useDidUpdate } from 'common/hooks';
import { useController } from 'common/context/controller';
import { Button } from 'common/components/Button';

import { Details } from 'Group/Widget/Header/Details/Details';
import { GroupMembershipButton } from 'Group/Widget/GroupMembership';
import type { IControllerProps } from 'FeedWidget/types';

import { classes, st } from './GroupDetails.st.css';

export interface IGroupDetailsProps {}

export const GroupDetails: React.FC<IGroupDetailsProps> = () => {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();

  const { groupId } = useSelector(selectAppData);
  const { group$ } = useController<IControllerProps>();
  const group = useSelector(selectGroup(groupId));

  useDidUpdate(() => {
    group$.fetch(groupId);
  }, [groupId]);

  if (!group.id) {
    return null;
  }

  return (
    <div
      className={st(classes.root, {
        mobile: isMobile,
      })}
    >
      <div className={classes.details}>
        <Details groupId={groupId} />
      </div>
      <div className={classes.actions}>
        <GroupMembershipButton groupId={groupId} />

        <UISref state="group" params={{ slug: group.slug }}>
          <Button as="a" fullWidth={isMobile} priority={ButtonPriority.primary}>
            {t('groups-web.go-to-group.label')}
          </Button>
        </UISref>
      </div>
    </div>
  );
};
