import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { selectAppData } from 'store/application/selectors';

import { EmptyState } from 'common/components/EmptyState';

import { GroupMembershipDialogs } from 'Group/Widget/GroupMembership';

import { Feed } from './Feed/loadable';
import { GroupDetails } from './GroupDetails';
import { FEED_WIDGET_WRAPPER } from './dataHooks';

import { st, classes } from './Widget.st.css';

export const Widget = memo(
  () => {
    const { t } = useTranslation();

    const { groupId } = useSelector(selectAppData);

    if (!groupId) {
      return (
        <EmptyState
          title={t('groups-web.settings.empty.title')}
          content={t('groups-web.settings.empty.description')}
        />
      );
    }

    return (
      <div className={st(classes.root, {})} data-hook={FEED_WIDGET_WRAPPER}>
        <GroupMembershipDialogs />
        <GroupDetails />
        <Feed />
      </div>
    );
  },
  () => true,
);
