import React from 'react';
import { WidgetProps } from '@wix/yoshi-flow-editor';
import { pick } from 'lodash';

import {
  WixCommentsApiProvider,
  CommentsProps,
} from '@wix/comments-ooi-client';

import { SocialGroupStore } from 'store/SocialGroupsStore';

import { ControllerProvider } from 'common/context/controller';
import { Router } from 'common/router';
import { Toasts } from 'common/components/Toasts';

import { IControllerProps } from '../types';

import { Widget } from './Widget';
import { ThemeProvider } from 'common/context/theme';

function FeedWidget(props: WidgetProps<IControllerProps & CommentsProps>) {
  const methods = pick(props, 'application$', 'feed$', 'group$', 'router$');

  return (
    <SocialGroupStore state={props.store}>
      <ThemeProvider host={props.host}>
        <ControllerProvider controller={methods}>
          <WixCommentsApiProvider {...(props as any)}>
            <Toasts />
            <Router>
              <Widget />
            </Router>
          </WixCommentsApiProvider>
        </ControllerProvider>
      </ThemeProvider>
    </SocialGroupStore>
  );
}

export default FeedWidget;
